.v-icon-red {
    color: #cc0000;
}

.v-icon-facebook::before {
    color: #1877f2;
}

.v-icon-facebook-ring::before {
    color: #1877f2;
}

.v-icon-twitter-ring::before {
    color: #1b9df0;
}

.v-icon-youtube-ring::before {
    color: #ff0000;
}

.v-icon-instagram-ring::before {
    color: #f00075;
}

.v-icon-pinterest-ring::before {
    color: #e60023;
}

.v-icon-odnoklassniki-ring::before {
    color: #ee8208;
}

.v-icon-weibo-ring::before {
    color: #e83339;
}

.v-icon-qq-ring::before {
    color: #12b7f5;
}

.v-icon-youku-ring::before {
    color: #8bd7f8;
}

.v-icon-line::before {
    color: #00c300;
}

.v-icon-wechat-ring::before {
    color: #00cc0a;
}

.v-icon-vkontakte-ring::before {
    color: #2787f5;
}

.v-icon-google-ring::before {
    color: #4285f4;
}

.v-icon-apple-ring::before {
    color: #000000;
}

.v-icon-blogger-ring::before {
    color: #f59241;
}

.v-icon-qzone-ring::before {
    color: #00a3ff;
}

.v-icon-douban-ring::before {
    color: #00b51e;
}

.v-icon-rss::before {
    color: #f47c00;
}

.v-icon-rss-ring::before {
    color: #f47c00;
}

.v-icon-renren-ring::before {
    color: #247ec6;
}

.v-icon-messenger-filled::before {
    color: #0a7cff;
}

.v-icon-whatsapp-filled::before {
    color: #25d366;
}

.v-icon-tqq-ring::before {
    color: #3699d5;
}

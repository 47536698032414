.beauty-edit-logo::before {
    content: "";
    box-sizing: content-box;
    background-image: url("../Icons/beauty-edit.svg");
    background-size: contain;
    background-repeat: no-repeat;
    display: inline-block;
    vertical-align: middle;
    height: 60px;
    width: 112px;
}

.beauty-edit-logo:lang(tr-tr)::before {
    background-image: url("../Icons/beauty-edit-turkey.svg");
    width: 125px;
}

@media screen and (max-width: 479px) {
    .beauty-edit-logo::before {
        height: 40px;
        width: 75px;
    }
    .beauty-edit-logo:lang(tr-tr)::before {
        width: 83px;
    }
}

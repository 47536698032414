/* 
  Icon fonts are loaded from static storage https://static.oriflame.com/. 
  Icon classes are defined in https://static.oriflame.com/icons/v3/style.css and in repository ori-icons.
  ! Do NOT add new icons or icon alis classes here - contribute to the ori-icons repository!
*/

/* common before pseudoelement icon styling ( not usable for inputs AFAIK )
    basic startup helper for styling icons based on sprites
    grid currently used in Nova sprite is 20px x 20px
-------------------------------------------------------------------------- */

/* Kendo icons */
.k-icon,
.k-tool-icon,
.k-grouping-dropclue,
.k-drop-hint,
.k-column-menu .k-sprite,
.k-summary .with-icon::before,
.title-icon::before,
.error-icon::before,
.success-icon::before {
    background: none;
}

/* for icons that are used as links (toggle, edit, delete ... ) but are without href */
.icon-link {
    cursor: pointer;
}

/* #region common webfont icons */
/* ---------------------------------------------------------------- */

/* Oriflame icons font - used for regular texts; no bolds so far */

*[class*="v-icon-"] {
    text-rendering: optimizeLegibility;
    text-rendering: geometricPrecision;
    text-rendering: optimizeSpeed;
    font-smooth: always;
    -webkit-font-smoothing: antialiased;
    font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

*[class*='v-icon-']::before,
*[class*='icon-'] a::after,
*[class*='k-i-']::before,
*[class*='v-after-icon-']::after,
.rsArrowIcn::before,
.icon-arrow::before,
.error-icon::before,
.title-icon::before,
.with-icon::before,
.success-icon::before,
.toggle::after,
.ui-digital-catalogue .product-slider-nav .prev::before,
.ui-digital-catalogue .product-slider-nav .next::before,
.ui-new-filter-bar .filtered-item::before,
.ui-filter-bar .filter-box .heading.expanded::before, /* it looks like this is not used at all but it should stay until we are certain */
.k-update::before,
.dl-message::before,
.k-filter::before,
.k-edit::before,
.dl-warning::before, /* it looks like this is not used at all but it should stay until we are certain */
.k-warning::before,
.shipping-offer::before,
.dl-navigation > li:first-child > a::after,
.ui-facet-navigation .right-arrow::after,
.ui-brand-navbar .dropdown .brand-label::after,
.ui-brand-megamenu .trigger::after,
.ui-accordion .expanded.toggle::before,
.ui-shades-box .opened .toggle::before {
    display: inline-block;
    font-family: "IconsOri";
    text-transform: none;
    text-decoration: none; /* IE bugfix */
    font-weight: normal; /* keep the icons the same also when element has bold text */
}

/* exceptions */
.ui-accordion .expanded.toggle::after,
.ui-shades-box .opened .toggle::after,
.icon-wrapper a::after {
    display: none;
}

/* This media query type has little support in screen readers, usually is ignored completely. */
@media speech {
    [class^="v-icon"]::before {
        content: none;
    }
}

/*
 Aliases for standard icon classes from @ori-icons.
 ! Do NOT add more aliases! All these aliases should be removed a replaced with standard classnames from @ori-ui. 
 ! This only adds unnecessary dependency on @ori-icons internals!
*/

.v-icon-consultant-sell::before,
li.icon-consultant-sell a::after {
    content: "\e966";
}

.ui-shades-box .opened .toggle::before,
.ui-new-filter-bar .filtered-item::before {
    content: "\2716";
}

.v-icon-minimize::before {
    content: "\2d";
}

.rsArrowLeft .rsArrowIcn::before {
    content: "\3c";
}

.v-after-icon-arrow-right-thin::after,
.rsArrowRight .rsArrowIcn::before,
.ui-facet-navigation .right-arrow::after,
.ui-side-navigation .toggle::after {
    content: "\3e";
}

.ui-facet-navigation .expanded.right-arrow.toggle::after,
.ui-side-navigation .expanded.toggle::after,
.ui-filter-bar .filter-box .heading.expanded::before, /* it looks like this is not used at all but it should stay until we are certain */
.ui-accordion .expanded.toggle::before {
    content: "\22c1";
}

.collapsed .icon-arrow::before {
    content: "\25ba";
}

.v-after-icon-arrow-right-submit::after {
    content: "\e951";
}

.v-icon-arrow-left-submit::before,
.v-after-icon-arrow-left-submit::after {
    content: "\e951";
    transform: rotate(180deg);
}

.v-icon-arrow-up-submit::before,
.v-after-icon-arrow-up-submit::after {
    content: "\e951";
    transform: rotate(-90deg);
}

.v-icon-arrow-down-submit::before,
.v-after-icon-arrow-down-submit::after {
    content: "\e951";
    transform: rotate(90deg);
}

.v-after-icon-arrow-down::after,
.ui-brand-navbar .dropdown .brand-label::after,
.ui-brand-megamenu .trigger::after,
.icon-arrow::before {
    content: "\25bc";
}

.dl-message::before,
.success-icon::before {
    content: "\2713";
}

.remove-button.v-icon-remove-circle:hover::before {
    content: "\e973";
}

.dl-warning::before, /* it looks like this is not used at all but it should stay until we are certain */
.error-icon::before {
    content: "\e931";
}

.error-icon:hover::before {
    content: "\e930";
}

.title-icon::before {
    content: "\e96d";
}

.v-icon-round-question:hover::before {
    content: "\e96c";
}
.title-icon:hover::before {
    color: var(--primary-main);
}

li.icon-consultant-mypages a::after {
    content: "\900";
}

li.icon-consultant-order a::after {
    content: "\e047";
}

li.icon-consultant-network a::after {
    content: "\e181";
}

li.icon-consultant-training a::after {
    content: "\26ca";
}

.shipping-offer::before {
    content: "\e92c";
}

.v-icon-search-error::before {
    content: "\e985";
}

.v-icon-circle-alert {
    background-color: red;
    text-align: center;
    border-radius: 50%;
    display: inline-block;
    width: 1.65em;
    height: 1.65em;
}

.v-icon-circle-alert::before {
    color: var(--common-white);
    line-height: 1.7;
    vertical-align: top;
}
/* #endregion common webfont icons */

/* Kendo icons */
.k-i-columns::before {
    content: "\2630";
    -ms-transform: rotate(-90deg);
    -webkit-transform: rotate(90deg);
    transform: rotate(90deg);
}
.k-i-seek-w::before {
    content: "\e95a";
}
.k-i-seek-e::before {
    content: "\e95d";
}

.k-i-sort-desc-sm::before,
.k-grid .k-header[aria-sort] .k-i-arrow-s::before {
    content: "\e96a";
}

.k-i-sort-asc-sm::before,
.k-grid .k-header[aria-sort] .k-i-arrow-n::before {
    content: "\e967";
}
.k-filter::before {
    content: "\e964";
}

.k-file > [class="k-icon"]::before {
    content: "\e960";
}

.k-file > .k-icon[class*="k-i-"]::before {
    content: "\e955";
}
.k-file > .k-icon.k-i-xlsx::before,
.k-i-file-excel::before {
    content: "\e962";
}
.k-i-file-pdf::before {
    content: "\e963";
}
.k-i-search::before {
    content: "\1f50e";
}
.k-calendar .k-i-arrow-w::before,
.k-pager-nav .k-i-arrow-w::before,
.k-i-back::before,
.k-pager-nav .k-i-arrow-60-left::before {
    content: "\3c";
}
.k-calendar .k-i-arrow-e::before,
.k-pager-nav .k-i-arrow-e::before,
.k-pager-nav .k-i-arrow-60-right::before,
.k-i-expand::before,
.k-panelbar-expand.k-i-arrow-s::before {
    content: "\3e";
}
.k-i-arrowhead-s::before,
.k-i-collapse::before,
.k-panelbar-collapse.k-i-arrow-n::before,
.k-i-more-vertical::before {
    content: "\22c1";
}
.k-i-arrow-up::before {
    content: "\22c0";
}
.k-i-close::before,
.k-delete::before {
    content: "\2716";
}
.k-i-arrow-w::before,
.k-i-arrow-60-left::before {
    content: "\3c";
}
.k-i-arrow-n::before,
.k-i-arrow-60-up::before {
    content: "\22c0";
}
.k-i-arrow-s::before,
.k-i-arrow-60-down::before {
    content: "\22c1";
}
.k-i-arrow-e::before,
.k-i-arrow-60-right::before {
    content: "\3e";
}
.k-i-tick::before,
.k-i-check::before,
.k-update::before,
.k-summary .with-icon.success::before {
    content: "\2713";
}
.k-i-refresh::before {
    content: "\e96f";
}
.k-i-minimize::before {
    content: "\2d";
}
.k-i-window-minimize::before {
    content: "\e96e";
}
.k-i-window-maximize::before {
    content: "\e92f";
}
.k-edit::before,
.k-i-pencil::before,
.k-i-edit::before {
    content: "\270e";
}
.k-i-calendar::before {
    content: "\e910";
}
.k-i-restore::before,
.k-i-window-restore::before {
    content: "\e95f";
}
.k-icon.k-add::before,
.k-i-plus::before,
.k-i-add::before {
    content: "\2b";
}
.k-icon.k-cancel::before,
.k-i-cancel::before {
    content: "\1f6c7";
}
.k-summary .with-icon.error::before,
.k-warning::before {
    content: "\e931";
}
.k-i-clock::before {
    content: "\e94b";
}
/* #endregion kendo loading animated icons */

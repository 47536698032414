/* #region loading animated spinner */
.k-loading::before,
.k-loading-image::before {
    content: "";
    box-sizing: content-box;
    background-image: url("../Icons/k-loader.svg");
    background-size: cover;
    position: relative;
    z-index: 1;
    display: inline-block;
}

/* small loader for kendo widgets (16x16px) */
.k-loading::before {
    top: 0;
    right: 0;
    width: 16px;
    height: 16px;
}

.k-loading.right::before {
    position: absolute;
    right: 5%;
    top: 50%;
    -webkit-transform: translateY(-50%); /* Safari */
    -ms-transform: translateY(-50%); /* IE9 */
    transform: translateY(-50%);
}

.k-loading.left::before {
    position: absolute;
    left: 5%;
    top: 50%;
    -webkit-transform: translateY(-50%); /* Safari */
    -ms-transform: translateY(-50%); /* IE9 */
    transform: translateY(-50%);
}

.k-loading.center::before {
    position: absolute;
    left: 50%;
    top: 50%;
    -webkit-transform: translateY(-50%) translateX(-50%); /* Safari */
    -ms-transform: translateY(-50%) translateX(-50%); /* IE9 */
    transform: translateY(-50%) translateX(-50%);
}

/* standard loaded in kendo progress (48x48px) */
.k-loading-image::before {
    width: 48px;
    height: 48px;
}

.k-ie .k-loading::before,
.k-edge .k-loading::before,
.k-ie .k-loading-image::before,
.k-edge .k-loading-image::before {
    -ms-animation: k-loader 1s steps(12) infinite;
    animation: k-loader 1s steps(12) infinite;
}

@-ms-keyframes k-loader {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(360deg);
    }
}

@keyframes k-loader {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(360deg);
    }
}

/* Required for spinner fixed position, used in spinner.js */
.k-loading-image.fixed {
    position: fixed;
}

/* Required for hidden spinner, used in spinner.js */
.k-loading-image.hidden {
    display: none;
}
